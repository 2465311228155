import { Close, Done, Refresh, Search } from "@mui/icons-material";
import {
  CircularProgress,
  createTheme,
  Divider,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./organization.styles.scss";

import { generalToastStyle } from "../../utils/toast.styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

const OrganizationComponent = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));

  const [searchText, setSearchText] = useState();
  const [orgs, setOrgs] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orgClicked, setOrgClicked] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);

  const getOrgs = () => {
    axios
      .get(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/organization/all.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        setOrgs(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    setOrgClicked(orgs.indexOf(row));
    setShowDrawer(true);
  };

  const allowOrg = (orgId) => {
    let formData = new FormData();
    formData.append("org", orgId);

    axios
      .post(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/organization/allow.php",
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getOrgs();
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const blockOrg = (orgId) => {
    let formData = new FormData();
    formData.append("org", orgId);

    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/organization/block.php",
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getOrgs();
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  useEffect(() => {
    getOrgs();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="organization-component">
      <ToastContainer />

      {/* Drawer to show org details */}
      {orgs === null || orgs.length === 0 ? (
        <div />
      ) : (
        <ThemeProvider theme={theme}>
          <SwipeableDrawer
            anchor="right"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <div
              style={{
                height: "200px",
                width: "600px",
                backgroundImage: `url(https://api.sadashrijewelkart.com/assets/${orgs[orgClicked].cover_image})`,
                marginTop: "20px",
                marginLeft: "20px",
                marginRight: "20px",
                borderRadius: "5px",
                objectFit: "contain",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={`https://api.sadashrijewelkart.com/assets/${orgs[orgClicked].logo}`}
                style={{
                  height: "120px",
                  borderRadius: "100%",
                  marginLeft: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                marginTop: "20px",
                marginLeft: "20px",
                fontFamily: '"Work Sans", sans-serif',
              }}
            >
              {orgs[orgClicked].name}
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                Registered At :
              </div>
              <div
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                {orgs[orgClicked].created_at}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                GSTIN :
              </div>
              <div
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                {orgs[orgClicked].gstin}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                GSTIN Verified :
              </div>
              <div
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                {orgs[orgClicked].gstin_verified === "1" ? "True" : "False"}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                PAN Number :
              </div>
              <div
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                {orgs[orgClicked].pan}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                Contact Email :
              </div>
              <div
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                {orgs[orgClicked].contact_email}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                BIS Certificate :
              </div>
              <a
                href={`https://api.sadashrijewelkart.com/assets/${orgs[orgClicked].bis}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                  color: "#A36E29",
                  textDecoration: "none",
                }}
              >
                View Certificate
              </a>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: '"Work Sans", sans-serif',
                }}
              >
                Brand Proof :
              </div>
              <a
                href={`https://api.sadashrijewelkart.com/assets/${orgs[orgClicked].brand_proof}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontFamily: '"Work Sans", sans-serif',
                  color: "#A36E29",
                  textDecoration: "none",
                }}
              >
                View Proof
              </a>
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
              }}
            >
              Organization Members :
            </div>
            {orgs[orgClicked].members.map((m) => {
              return (
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "10px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    fontFamily: '"Work Sans", sans-serif',
                    padding: "10px",
                  }}
                >
                  {m.name}
                  <br />
                  {m.mobile}
                </div>
              );
            })}
            <div
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
              }}
            >
              Addresses :
            </div>
            {orgs[orgClicked].addresses.map((a) => {
              return (
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "10px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    fontFamily: '"Work Sans", sans-serif',
                    padding: "10px",
                  }}
                >
                  {a.add_line_1}
                  <br />
                  {a.add_line_2}
                  <br />
                  {a.city + ", " + a.state + ", " + a.pincode}
                </div>
              );
            })}
            <div
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
              }}
            >
              Bank Accounts :
            </div>
            {orgs[orgClicked].banks.map((b) => {
              return (
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "10px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    fontFamily: '"Work Sans", sans-serif',
                    padding: "10px",
                  }}
                >
                  {b.ac_holder_name}
                  <br />
                  {b.ac_number}
                  <br />
                  {b.ac_ifsc}
                  <br />
                  {b.ac_bank_name}
                </div>
              );
            })}
            <div
              style={{
                height: "200px",
                marginTop: "20px",
              }}
            />
          </SwipeableDrawer>
        </ThemeProvider>
      )}

      <div className="head">
        <div className="head-txt">Organizations</div>
        <Refresh className="head-refresh" />
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">2 Organization(s) </span>
          </p>
        </div>
        <div class="input-container">
          <Search class="icon" />
          <input
            class="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          {orgs === null ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <>
              <TableContainer style={{ maxHeight: "95%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>GSTIN</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell>Registered At</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orgs
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell
                              className="name-content"
                              onClick={() => rowClicked(row)}
                            >
                              <img
                                className="company-img"
                                alt="org"
                                src={`https://api.sadashrijewelkart.com/assets/${row.logo}`}
                              />
                              {row.name}
                            </TableCell>
                            <TableCell>{row.gstin}</TableCell>
                            <TableCell>{row.contact_email}</TableCell>
                            <TableCell>{row.created_at}</TableCell>
                            <TableCell className="actions-content">
                              {row.admin_verified === "0" ||
                              row.admin_verified === "2" ? (
                                <Done
                                  className="allow"
                                  onClick={() => allowOrg(row.id)}
                                />
                              ) : (
                                <div />
                              )}
                              {row.admin_verified === "0" ||
                              row.admin_verified === "1" ? (
                                <Close
                                  className="block"
                                  onClick={() => blockOrg(row.id)}
                                />
                              ) : (
                                <div />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={orgs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default OrganizationComponent;
