import React from "react";
import { Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomDrawer from "../../components/drawer/drawer.component";
import ReviewComponent from "../../components/reviews/review.component";
import { Height } from "@mui/icons-material";
import CouponsComponent from "../../components/coupons/coupons.component";

const CouponsPage = () => {
  let { section } = useParams();

  return (
    <div className="product">
      <CustomDrawer section={"coupons"} />

      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <CouponsComponent />
      </div>
    </div>
  );
};

export default CouponsPage;
