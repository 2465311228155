import {
  Button,
  createTheme,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CurrencyRupee } from "@mui/icons-material";
import "./rates.styles.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

const RatesComponent = () => {
  const [latestRates, setLatestRates] = useState(null);
  const [historicalRates, setHistoricalRates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editableRates, setEditableRates] = useState({
    gold24: 0,
    gold22: 0,
    gold18: 0,
    gold14: 0,
    silver: 0,
  });
  const [isEditing, setIsEditing] = useState(false);

  const fetchRates = async () => {
    try {
      const admin = JSON.parse(localStorage.getItem("admin"));

      // Fetch latest rates
      const latestResponse = await axios.get(
        `https://api.sadashrijewelkart.com/v1.0.0/admin/jewelleryInventory/jewellryInventory.php?type=get_latest`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLatestRates(latestResponse.data.response);
      setEditableRates(latestResponse.data.response.jewelry_prices);

      // Fetch historical rates
      const historicalResponse = await axios.get(
        `https://api.sadashrijewelkart.com/v1.0.0/admin/jewelleryInventory/jewellryInventory.php?type=all`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setHistoricalRates(historicalResponse.data.response);
    } catch (error) {
      console.error("Error fetching rates:", error);
    }
  };

  useEffect(() => {
    fetchRates();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateRates = (gold22Rate) => {
    const gold24Rate = (gold22Rate / 22) * 24;
    const gold18Rate = (gold22Rate / 22) * 18;
    const gold14Rate = (gold22Rate / 22) * 14;

    setEditableRates((prev) => ({
      ...prev,
      gold24: Math.round(gold24Rate),
      gold18: Math.round(gold18Rate),
      gold14: Math.round(gold14Rate),
    }));
  };

  const handleRateChange = (type, value) => {
    if (type === "gold22") {
      calculateRates(Number(value));
    }
    setEditableRates((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const admin = JSON.parse(localStorage.getItem("admin"));
      // Convert all rates to float values
      const formattedRates = {
        gold24: parseFloat(editableRates.gold24) || 0,
        gold22: parseFloat(editableRates.gold22) || 0,
        gold18: parseFloat(editableRates.gold18) || 0,
        gold14: parseFloat(editableRates.gold14) || 0,
        silver: parseFloat(editableRates.silver) || 0,
      };
      await axios.post(
        `https://api.sadashrijewelkart.com/v1.0.0/admin/jewelleryInventory/jewellryInventory.php`,
        {
          type: "add_jewellery_prices",
          jewellery_prices: formattedRates,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsEditing(false);
      toast.success("Rates updated successfully!");
      fetchRates(); // Refresh data after successful update
    } catch (error) {
      console.error("Error updating rates:", error);
      toast.error("Failed to update rates");
    }
  };

  return (
    <div className="rates-component">
      <ToastContainer />

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Rates</div>
        {isEditing ? (
          <div>
            <Button
              variant="outlined"
              onClick={() => setIsEditing(false)}
              style={{
                marginRight: "10px",
                color: "#a36e29",
                border: "1px solid #a36e29",
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              style={{
                marginRight: "30px",
                backgroundColor: "#a36e29",
                color: "white",
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsEditing(true)}
            style={{
              marginRight: "30px",
              backgroundColor: "transparent",
              color: "#a36e29",
              border: "1px solid #a36e29",
            }}
          >
            Edit
          </Button>
        )}
      </div>
      <Divider />

      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={2}
          rowSpacing={0}
          rowGap={0}
          style={{
            padding: "30px",
          }}
        >
          <Grid item xs={6}>
            <Paper
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                22KT Gold Rate :
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrencyRupee style={{ marginRight: "5px" }} />
                {isEditing ? (
                  <TextField
                    value={editableRates.gold22}
                    onChange={(e) => handleRateChange("gold22", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "20px",
                    }}
                  >
                    {editableRates.gold22 || 0}
                  </Typography>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                Silver Rate :
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrencyRupee style={{ marginRight: "5px" }} />
                {isEditing ? (
                  <TextField
                    value={editableRates.silver}
                    onChange={(e) => handleRateChange("silver", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "20px",
                    }}
                  >
                    {editableRates.silver || 0}
                  </Typography>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                24KT Gold Rate :
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrencyRupee style={{ marginRight: "5px" }} />
                {isEditing ? (
                  <TextField
                    value={editableRates.gold24}
                    onChange={(e) => handleRateChange("gold24", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "20px",
                    }}
                  >
                    {editableRates.gold24 || 0}
                  </Typography>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                18KT Gold Rate :
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrencyRupee style={{ marginRight: "5px" }} />
                {isEditing ? (
                  <TextField
                    value={editableRates.gold18}
                    onChange={(e) => handleRateChange("gold18", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "20px",
                    }}
                  >
                    {editableRates.gold18 || 0}
                  </Typography>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                14KT Gold Rate :
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrencyRupee style={{ marginRight: "5px" }} />
                {isEditing ? (
                  <TextField
                    value={editableRates.gold14}
                    onChange={(e) => handleRateChange("gold14", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "20px",
                    }}
                  >
                    {editableRates.gold14 || 0}
                  </Typography>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Paper
          style={{
            width: "95%",
            margin: "0 30px",
            padding: "20px",
          }}
        >
          <Typography
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Historical Rates
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>24KT Gold</TableCell>
                  <TableCell>22KT Gold</TableCell>
                  <TableCell>18KT Gold</TableCell>
                  <TableCell>14KT Gold</TableCell>
                  <TableCell>Silver</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historicalRates
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((rate) => (
                    <TableRow key={rate.id}>
                      <TableCell>
                        {new Date(rate.created_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell>₹{rate.jewelry_prices.gold24}</TableCell>
                      <TableCell>₹{rate.jewelry_prices.gold22}</TableCell>
                      <TableCell>₹{rate.jewelry_prices.gold18}</TableCell>
                      <TableCell>₹{rate.jewelry_prices.gold14}</TableCell>
                      <TableCell>₹{rate.jewelry_prices.silver}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={historicalRates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default RatesComponent;
