const SellerDetailComponent = ({ orderDetails }) => {
  return (
    <div
      style={{
        width: "calc(100% - 80px)",
        padding: "40px",
        marginBottom: "30px",
        height: "max-content",
        minHeight: "300px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <p style={{ fontSize: "1.2rem", fontWeight: "600", margin: 0 }}>Seller</p>
      <p
        style={{
          color: "#A36E29",
          fontWeight: 600,
          margin: 0,
          fontSize: "1.2rem",
        }}
      >
        {orderDetails?.company_name}
      </p>
      <p
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          margin: 0,
          marginTop: "30px",
        }}
      >
        Contact Information
      </p>

      <p
        style={{
          color: "#A36E29",
          fontWeight: 600,
          margin: 0,
          fontSize: "1.2rem",
        }}
      >
        {orderDetails?.company_email}
      </p>

      <p
        style={{
          color: "#a7a7a7",
          fontWeight: 500,
          margin: 0,
          fontSize: "1.2rem",
        }}
      >
        +{orderDetails?.seller_mobile}
      </p>
      <p
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          margin: 0,
          marginTop: "30px",
        }}
      >
        Seller Address
      </p>

      <p
        style={{
          color: "#a7a7a7",
          fontWeight: 500,
          margin: 0,
          fontSize: "1.2rem",
        }}
      >
        {orderDetails?.company_address?.add_line_1}
        <br></br>
        {orderDetails?.company_address?.add_line_2} <br></br>
        {orderDetails?.company_address?.city}{" "}
        {orderDetails?.company_address?.state}
        {orderDetails?.company_address?.pincode}
      </p>

      <p
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          margin: 0,
          marginTop: "30px",
        }}
      >
        Billing Address
      </p>
      <div
        style={{
          color: "#a7a7a7",
          fontWeight: 500,
          margin: 0,
          fontSize: "1.2rem",
        }}
      >
        Same as Shipping Address
      </div>
    </div>
  );
};

export default SellerDetailComponent;
