import {
  Add,
  AddAPhoto,
  Delete,
  Done,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Restore
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  createTheme,
  Divider,
  IconButton,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./categories.styles.scss";

import { generalToastStyle } from "../../utils/toast.styles";
import InputTextField from "../input-text-field/input-text-field.component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

/*
Drawer State
1 - Add new category
2 - Edit Category
3 - Add new sub category
4 - Edit Sub Category
*/

const CategoryComponent = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [searchText, setSearchText] = useState();

  const [newCategoryImage, setNewCategoryImage] = useState();
  const [newCategoryName, setNewCategoryName] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [drawerState, setDrawerState] = useState(1);
  const [displaySubcategories, setDisplayCategories] = useState([]);

  const [categories, setCategories] = useState(null);
  const [page, setPage] = useState(0);
  const [categoryClicked, setCategoryClicked] = useState(0);
  const [subCategoryClicked, setSubCategoryClicked] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDrawer, setShowDrawer] = useState(false);

  const getCategories = () => {
    axios
      .get(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/all.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCategories(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    setCategoryClicked(categories.indexOf(row));
    setShowDrawer(true);
  };

  // Add a category
  const addCategory = () => {
    if (
      newCategoryName === null ||
      newCategoryName === "" ||
      typeof newCategoryName === "undefined"
    ) {
      toast.warn("Category Name is required!", generalToastStyle);
    } else {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("type", "category");
      if (newCategoryImage !== null) formData.append("image", newCategoryImage);
      formData.append("name", newCategoryName);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/add.php",
          formData,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((_) => {
          setShowLoading(false);
          setNewCategoryImage(null);
          setNewCategoryName(null);
          setShowDrawer(false);
          getCategories();
          toast("New Category added successfully!", generalToastStyle);
        })
        .catch((err) => {
          setShowLoading(false);
          toast.error(err.response.data.message, generalToastStyle);
        });
    }
  };

  // Add a sub-category
  const addSubCategory = () => {
    if (
      newCategoryName === null ||
      newCategoryName === "" ||
      typeof newCategoryName === "undefined"
    ) {
      toast.warn("Syb Category Name is required!", generalToastStyle);
    } else {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("type", "sub-category");
      formData.append("category", categories[categoryClicked]["id"]);
      if (newCategoryImage !== null) formData.append("image", newCategoryImage);
      formData.append("name", newCategoryName);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/add.php",
          formData,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((_) => {
          setShowLoading(false);
          setNewCategoryImage(null);
          setNewCategoryName("");
          setShowDrawer(false);
          getCategories();
          toast("New Sub Category added successfully!", generalToastStyle);
        })
        .catch((err) => {
          setShowLoading(false);
          toast.error(err.response.data.message, generalToastStyle);
        });
    }
  };

  // Update a category
  const updateCategory = () => {
    if (
      newCategoryImage !== null ||
      newCategoryName !== categories[categoryClicked]["name"]
    ) {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("type", "category");
      formData.append("id", categories[categoryClicked]["id"]);
      if (newCategoryImage !== null) formData.append("image", newCategoryImage);
      if (newCategoryName !== categories[categoryClicked]["name"])
        formData.append("name", newCategoryName);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/update.php",
          formData,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((_) => {
          setShowLoading(false);
          setNewCategoryImage(null);
          setNewCategoryName(null);
          setShowDrawer(false);
          getCategories();
          toast("Category updated successfully!", generalToastStyle);
        })
        .catch((err) => {
          setShowLoading(false);
          toast.error(err.response.data.message, generalToastStyle);
        });
    } else {
      setShowLoading(false);
      setNewCategoryImage(null);
      setNewCategoryName(null);
      setShowDrawer(false);
    }
  };

  // Update a sub-category
  const updateSubCategory = () => {
    if (
      newCategoryImage !== null ||
      newCategoryName !==
        categories[categoryClicked]["sub_categories"][subCategoryClicked][
          "name"
        ]
    ) {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("type", "sub-category");
      formData.append(
        "id",
        categories[categoryClicked]["sub_categories"][subCategoryClicked]["id"]
      );
      if (newCategoryImage !== null) formData.append("image", newCategoryImage);
      if (
        newCategoryName !==
        categories[categoryClicked]["sub_categories"][subCategoryClicked][
          "name"
        ]
      )
        formData.append("name", newCategoryName);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/update.php",
          formData,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((_) => {
          setShowLoading(false);
          setNewCategoryImage(null);
          setNewCategoryName(null);
          setShowDrawer(false);
          getCategories();
          toast("Sub Category updated successfully!", generalToastStyle);
        })
        .catch((err) => {
          setShowLoading(false);
          toast.error(err.response.data.message, generalToastStyle);
        });
    } else {
      setShowLoading(false);
      setNewCategoryImage(null);
      setNewCategoryName(null);
      setShowDrawer(false);
    }
  };

  // Delete a category
  const deleteCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  // Delete a sub category
  const deleteSubCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "sub-category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Sub Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(newCategoryImage);
  }, [newCategoryImage]);

  return (
    <div className="category-component">
      <ToastContainer />

      {/* Swipable Drawer */}
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowLoading(false);
            setNewCategoryImage(null);
            setNewCategoryName(null);
            setShowDrawer(false);
          }}
          onOpen={() => setShowDrawer(true)}
        >
          <div
            style={{
              padding: "20px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
                marginBottom: "40px",
              }}
            >
              {drawerState === 4
                ? "Update Sub Category"
                : drawerState === 3
                ? "Add New Sub Category"
                : drawerState === 2
                ? "Update Category"
                : "Add New Category"}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <label htmlFor="cat-image">
                <div
                  style={{
                    height: "90px",
                    width: "90px",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {drawerState === 4 ? (
                    newCategoryImage ? (
                      <img
                        src={URL.createObjectURL(newCategoryImage)}
                        alt="selected-cat"
                        height="85px"
                        width="85px"
                      />
                    ) : categories[categoryClicked]["sub_categories"][
                        subCategoryClicked
                      ]["image"] === "" ? (
                      <AddAPhoto />
                    ) : (
                      <img
                        src={`https://api.sadashrijewelkart.com/assets/${categories[categoryClicked]["sub_categories"][subCategoryClicked]["image"]}`}
                        alt="selected-cat"
                        height="85px"
                        width="85px"
                      />
                    )
                  ) : drawerState === 2 ? (
                    newCategoryImage ? (
                      <img
                        src={URL.createObjectURL(newCategoryImage)}
                        alt="selected-cat"
                        height="85px"
                        width="85px"
                      />
                    ) : categories[categoryClicked]["image"] === "" ? (
                      <AddAPhoto />
                    ) : (
                      <img
                        src={`https://api.sadashrijewelkart.com/assets/${categories[categoryClicked]["image"]}`}
                        alt="selected-cat"
                        height="85px"
                        width="85px"
                      />
                    )
                  ) : newCategoryImage ? (
                    <img
                      src={URL.createObjectURL(newCategoryImage)}
                      alt="selected-cat"
                      height="85px"
                      width="85px"
                    />
                  ) : (
                    <AddAPhoto />
                  )}
                </div>
              </label>
              <input
                type="file"
                accept="image/*"
                id="cat-image"
                style={{
                  display: "none",
                }}
                onChange={(e) => {
                  const file = e.target.files;
                  console.log("file", file);

                  if (file) {
                    setNewCategoryImage(file[0]);
                  }
                }}
              />
              <div
                style={{
                  width: "300px",
                  marginRight: "20px",
                }}
              >
                <InputTextField
                  title={
                    drawerState === 3 || drawerState === 4
                      ? "Sub Category Name"
                      : "Category Name"
                  }
                  value={newCategoryName}
                  onEdit={(e) => setNewCategoryName(e.target.value)}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{
                    border: "1px solid #a36e29",
                    padding: "10px",
                  }}
                  onClick={() => {
                    setNewCategoryName(null);
                    setNewCategoryImage(null);
                  }}
                  disabled={drawerState === 2 || drawerState === 4}
                >
                  <Restore />
                </Button>
                <Button
                  style={{
                    backgroundColor: "#a36e29",
                    color: "white",
                    padding: "10px",
                    marginRight: "20px",
                  }}
                  onClick={
                    drawerState === 4
                      ? updateSubCategory
                      : drawerState === 3
                      ? addSubCategory
                      : drawerState === 2
                      ? updateCategory
                      : addCategory
                  }
                >
                  {showLoading ? (
                    <CircularProgress
                      style={{
                        color: "white",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    <Done />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </ThemeProvider>

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Categories</div>

        <Button
          className="button"
          onClick={() => {
            setShowDrawer(true);
            setDrawerState(24);
          }}
        >
          <Add /> Add
        </Button>
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">2 Categories(s) </span>
          </p>
        </div>
        {/* <div class="input-container">
          <Search class="icon" />
          <input
            class="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div> */}
      </div>
      <Divider />

      <Paper className="table-paper">
        {categories === null ? (
          <CircularProgress
            style={{
              margin: "auto",
              display: "flex",
              height: "100%",
            }}
          />
        ) : (
          <Fragment>
            <TableContainer
              style={{
                height: "calc(100% - 50px)",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Sub Categories</TableCell>
                    <TableCell>Registered At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <Fragment key={row.id}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell>
                              <IconButton
                                className="arrow-icon"
                                onClick={() =>
                                  setDisplayCategories(
                                    (prevDisplaySubcategories) => {
                                      const updatedDisplaySubcategories = [
                                        ...prevDisplaySubcategories,
                                      ];
                                      const index =
                                        updatedDisplaySubcategories.indexOf(
                                          row.id
                                        );

                                      if (index !== -1) {
                                        updatedDisplaySubcategories.splice(
                                          index,
                                          1
                                        );
                                      } else {
                                        updatedDisplaySubcategories.push(
                                          row.id
                                        );
                                      }

                                      return updatedDisplaySubcategories;
                                    }
                                  )
                                }
                              >
                                {displaySubcategories.includes(row.id) ? (
                                  <KeyboardArrowUp />
                                ) : (
                                  <KeyboardArrowDown />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              className="name-content"
                              onClick={() => rowClicked(row)}
                            >
                              {row.image ? (
                                <img
                                  className="company-img"
                                  alt="org"
                                  src={`https://api.sadashrijewelkart.com/assets/${row.image}`}
                                />
                              ) : (
                                <img
                                  className="company-img"
                                  alt="default"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/logoNew.png"
                                  }
                                />
                              )}
                              {row.name}
                            </TableCell>
                            <TableCell>
                              {row.sub_categories !== null
                                ? `${row.sub_categories.length} Sub-categori(es)`
                                : "0 Sub-categories"}
                            </TableCell>
                            <TableCell>{row.created_at}</TableCell>
                            <TableCell className="actions-content">
                              <Edit
                                className="allow"
                                onClick={() => {
                                  setDrawerState(2);
                                  setNewCategoryName(row.name);
                                  setCategoryClicked(categories.indexOf(row));
                                  setShowDrawer(true);
                                }}
                              />
                              <Delete
                                className="delete"
                                onClick={() => deleteCategory(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                          {displaySubcategories.includes(row.id) && (
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Table
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell>Sub Category Name</TableCell>
                                      <TableCell>Registered At</TableCell>
                                      <TableCell>Actions</TableCell>
                                      <TableCell>
                                        <Button
                                          onClick={() => {
                                            setCategoryClicked(
                                              categories.indexOf(row)
                                            );
                                            setDrawerState(3);
                                            setShowDrawer(true);
                                          }}
                                        >
                                          Add Sub-Category
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row.sub_categories &&
                                      row.sub_categories.map((sc) => {
                                        return (
                                          <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={sc.id}
                                          >
                                            <TableCell>
                                              <IconButton />
                                            </TableCell>
                                            <TableCell className="name-content">
                                              {sc.image ? (
                                                <img
                                                  className="company-img"
                                                  alt="org"
                                                  src={`https://api.sadashrijewelkart.com/assets/${sc.image}`}
                                                />
                                              ) : (
                                                <img
                                                  className="company-img"
                                                  alt="default"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/logoNew.png"
                                                  }
                                                />
                                              )}

                                              {sc.name}
                                            </TableCell>
                                            <TableCell>
                                              {sc.created_at}
                                            </TableCell>
                                            <TableCell className="actions-content">
                                              <Edit
                                                className="allow"
                                                onClick={() => {
                                                  setDrawerState(4);
                                                  setNewCategoryName(sc.name);
                                                  setCategoryClicked(
                                                    categories.indexOf(row)
                                                  );
                                                  setSubCategoryClicked(
                                                    row.sub_categories.indexOf(
                                                      sc
                                                    )
                                                  );
                                                  setShowDrawer(true);
                                                }}
                                              />
                                              <Delete
                                                className="delete"
                                                onClick={() =>
                                                  deleteSubCategory(sc.id)
                                                }
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <IconButton />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          )}
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 200]}
              component="div"
              count={categories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Fragment>
        )}
      </Paper>
    </div>
  );
};

export default CategoryComponent;
