import { Divider, Tooltip } from "@mui/material";

const OrderInfoCard = ({
  orderDetail,
  setRefundDialogOpen,
  setRefundOrderId,
  setReturnTrackingDialogOpen,
  setReturnOrderId,
  setReturnReceivedOrderId,
  setReturnReceived,
}) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "30px",
        height: "max-content",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "160px",
          height: "160px",
          borderRadius: "12px",
          border: "1px solid #e7e7e7",
          overflow: "hidden",
        }}
      >
        <img
          src={`https://api.sadashrijewelkart.com/assets/${orderDetail.images[0]["file"]}`}
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          height: "160px",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ margin: 0, fontSize: "1.4rem", fontWeight: 600 }}>
            {orderDetail?.product_name}
          </div>
          {orderDetail?.shipment_status === "ORDER_CANCELED" ? (
            <Tooltip title="Refund will be processed after all other items are fulfilled">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100px",
                  padding: "5px",
                  border: "1px solid red",
                  borderRadius: "5px",
                  color: "red",
                }}
              >
                Cancelled
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "150px",
                padding: "5px",
                border:
                  orderDetail?.shipment_status === "ADMIN_INSPECTION_FAILED" ||
                  orderDetail?.shipment_status === "ORDER_RETURN_REQUEST" ||
                  orderDetail?.shipment_status ===
                    "ORDER_RETURN_REQUEST_APPROVED" ||
                  orderDetail?.shipment_status === "RETURN_RECEIVED_BY_ADMIN"
                    ? "1px solid red"
                    : orderDetail?.shipment_status === "ORDER_CREATED" ||
                      orderDetail?.shipment_status === "SELLER_VERIFIED" ||
                      orderDetail?.shipment_status === "ADMIN_RECEIVED" ||
                      orderDetail?.shipment_status ===
                        "INSPECTION_FAILED_ORDER_RECEIVED_BY_SELLER"
                    ? "1px solid #F99B1C59"
                    : "1px solid #cffbcf",
                borderRadius: "5px",
                color:
                  orderDetail?.shipment_status === "ADMIN_INSPECTION_FAILED" ||
                  orderDetail?.shipment_status === "ORDER_RETURN_REQUEST" ||
                  orderDetail?.shipment_status ===
                    "ORDER_RETURN_REQUEST_APPROVED" ||
                  orderDetail?.shipment_status === "RETURN_RECEIVED_BY_ADMIN"
                    ? "red"
                    : orderDetail?.shipment_status === "ORDER_CREATED" ||
                      orderDetail?.shipment_status === "SELLER_VERIFIED" ||
                      orderDetail?.shipment_status === "ADMIN_RECEIVED" ||
                      orderDetail?.shipment_status ===
                        "INSPECTION_FAILED_ORDER_RECEIVED_BY_SELLER"
                    ? "#F99B1C"
                    : "#008000",
              }}
            >
              {orderDetail?.shipment_status === "ADMIN_INSPECTION_FAILED" ||
              orderDetail?.shipment_status === "RETURN_RECEIVED_BY_ADMIN"
                ? "Returned"
                : orderDetail?.shipment_status === "ORDER_RETURN_REQUEST"
                ? "Return Requested"
                : orderDetail?.shipment_status ===
                  "ORDER_RETURN_REQUEST_APPROVED"
                ? "Return on way"
                : orderDetail?.shipment_status === "ORDER_CREATED" ||
                  orderDetail?.shipment_status ===
                    "INSPECTION_FAILED_ORDER_RECEIVED_BY_SELLER"
                ? "Unfullfilled"
                : orderDetail?.shipment_status === "SELLER_VERIFIED"
                ? "On it's way"
                : orderDetail?.shipment_status === "ADMIN_RECEIVED"
                ? "Received"
                : "Fullfilled"}
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              margin: 0,
              marginTop: "10px",
              color: "gray",
              lineHeight: "2rem",
              fontWeight: 500,
              fontSize: "1.1rem",
            }}
          >
            Quantity: {orderDetail?.quantity} pcs.
          </div>
          {(orderDetail?.shipment_status === "ORDER_CANCELED" ||
            orderDetail?.shipment_status === "RETURN_RECEIVED_BY_ADMIN") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "150px",
                padding: "5px",
                backgroundColor: "#A36E29",
                borderRadius: "5px",
                color: "white",
                height: "24px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setRefundOrderId(orderDetail?.order_detail_id);
                setRefundDialogOpen(true);
              }}
            >
              Refund
            </div>
          )}
          {orderDetail?.shipment_status === "ORDER_RETURN_REQUEST" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "150px",
                padding: "5px",
                backgroundColor: "#A36E29",
                borderRadius: "5px",
                color: "white",
                height: "24px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setReturnOrderId(orderDetail?.order_detail_id);
                setReturnTrackingDialogOpen(true);
              }}
            >
              Add Tracking
            </div>
          )}
          {orderDetail?.shipment_status === "ORDER_RETURN_REQUEST_APPROVED" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "150px",
                padding: "5px",
                backgroundColor: "#A36E29",
                borderRadius: "5px",
                color: "white",
                height: "24px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(orderDetail?.order_detail_id);
                setReturnReceivedOrderId(orderDetail?.order_detail_id);
                setReturnReceived();
              }}
            >
              Received
            </div>
          )}
        </div>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
            fontSize: "1.1rem",
          }}
        >
          Price :{" "}
          <span style={{ color: "black" }}> Rs: {orderDetail?.price}</span>
        </p>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
          }}
        >
          Deliver By: {Date(orderDetail?.estimated_date)}
        </p>
      </div>
    </div>
  );
};

const OrderSummaryComponent = ({
  orderDetails,
  setRefundDialogOpen,
  setRefundOrderId,
  setReturnOrderId,
  setReturnTrackingDialogOpen,
  setReturnReceivedOrderId,
  setReturnReceived,
}) => {
  return (
    <div
      style={{
        width: "90%",
        padding: "40px",
        height: "max-content",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <span
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          height: "max-content",
        }}
      >
        <div
          style={{
            fontSize: "2rem",
            fontWeight: 600,
            margin: 0,
          }}
        >
          Order Summary
        </div>
      </span>
      {orderDetails?.map((item) => (
        <OrderInfoCard
          orderDetail={item}
          setRefundDialogOpen={setRefundDialogOpen}
          setRefundOrderId={setRefundOrderId}
          setReturnOrderId={setReturnOrderId}
          setReturnTrackingDialogOpen={setReturnTrackingDialogOpen}
          setReturnReceivedOrderId={setReturnReceivedOrderId}
          setReturnReceived={setReturnReceived}
        />
      ))}

      <div
        style={{
          width: "calc(100% - 20px)",
          padding: "10px",
          borderRadius: "10px",
          border: "1px solid #e7e7e7",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            color: "#797979",
            fontWeight: 500,
            fontSize: "1rem",
          }}
        >
          <div style={{ width: "25%" }}>
            <p style={{ textAlign: "left" }}>Subtotal</p>
          </div>
          <div style={{ width: "60%" }}>
            <p style={{ textAlign: "left" }}>{orderDetails?.length} Item</p>
          </div>
          <div style={{ width: "15%" }}>
            <p style={{ textAlign: "right" }}>
              Rs:{" "}
              {orderDetails
                .reduce((sum, item) => sum + parseFloat(item.price), 0)
                .toFixed(2)}
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            color: "#797979",
            fontWeight: 500,
            fontSize: "1rem",
          }}
        >
          <div style={{ width: "25%" }}>
            <p style={{ textAlign: "left" }}>Discount</p>
          </div>
          <div style={{ width: "60%" }}>
            {/* <p style={{ textAlign: "left" }}>FIRST10</p> */}
          </div>
          <div style={{ width: "15%" }}>
            <p style={{ textAlign: "right" }}>
              - Rs: {parseFloat(orderDetails[0]?.discount_amount).toFixed(2)}
            </p>
          </div>
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            color: "#797979",
            fontWeight: 500,
            fontSize: "1rem",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <div style={{ width: "25%" }}>
            <p style={{ textAlign: "left" }}>
              <b>Total</b>
            </p>
          </div>
          <div style={{ width: "60%" }}>
            <p style={{ textAlign: "left" }}></p>
          </div>
          <div style={{ width: "15%" }}>
            <p style={{ textAlign: "right" }}>
              <b>Rs: {parseFloat(orderDetails[0]?.amount).toFixed(2)}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryComponent;
