import { Close, Done } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { generalToastStyle } from "../../utils/toast.styles";
import "./product.styles.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

const ProductComponent = () => {
  const product = JSON.parse(localStorage.getItem("admin"));
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState(); // for search
  const [products, setProducts] = useState(null); /// stores all the products
  const [showDrawer, setShowDrawer] = useState(false);
  const [message, setMessage] = useState();
  const [productClicked, setProductClicked] = useState(0);
  const [dialog, setDialog] = useState(false);

  const getProduct = () => {
    console.log(product);
    axios
      .get("https://api.sadashrijewelkart.com/v1.0.0/admin/product/all.php", {
        headers: {
          Authorization: `Bearer ${product.token}`,
        },
      })
      .then((response) => {
        setProducts(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleBlockProductDialog = () => {
    const requestData = {
      action: "product-block",
      id: id,
      reason: message,
    };
    console.log(id);
    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/update.php",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${product.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getProduct();
        setDialog(false);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const allowProduct = (productId) => {
    const requestData = {
      action: "product-allow",
      id: productId,
    };

    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/update.php",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${product.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getProduct();
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const blockProduct = (productId) => {
    console.log(productId);
    setId(productId);
    setDialog(true);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    setProductClicked(products.indexOf(row));
    setShowDrawer(true);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div className="product-component">
      <ToastContainer />
      {products === null || products.length === 0 ? (
        <></>
      ) : (
        <ThemeProvider theme={theme}>
          <SwipeableDrawer
            anchor="right"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            PaperProps={{ style: { width: "60%" } }} // Adjust the width as needed
          >
            {products && products.length > 0 && productClicked !== null && (
              <div style={{ padding: "1vw", paddingLeft: "2vw" }}>
                {/* Image Gallery */}
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  {products[productClicked].images &&
                    products[productClicked].images.map((image, index) => (
                      <div key={index} style={{ marginRight: "10px" }}>
                        {image.type === "img" ? (
                          <img
                            src={`https://api.sadashrijewelkart.com/assets/${image.file}`}
                            alt={`Product ${index + 1}`}
                            style={{ width: "300px" }}
                          />
                        ) : (
                          <video
                            controls
                            width="300"
                            style={{ objectFit: "cover" }}
                          >
                            <source
                              src={`https://api.sadashrijewelkart.com/assets/${image.file}`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    ))}
                </div>
                {/* Product Details */}
                <div style={{ marginBottom: "20px" }}>
                  <h2
                    style={{
                      marginBottom: "10px",
                      fontFamily: "'Work Sans', sans-serif",
                    }}
                  >
                    {products[productClicked].name}
                  </h2>

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      color: "#666",
                      fontSize: "0.9rem",
                      marginBottom: "15px",
                      fontFamily: "'Work Sans', sans-serif",
                    }}
                  >
                    <span>{products[productClicked].category}</span>
                    <span>•</span>
                    <span>{products[productClicked].sub_category}</span>
                  </div>

                  <div
                    style={{
                      color: "#444",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      marginBottom: "15px",
                      fontFamily: "'Work Sans', sans-serif",
                    }}
                  >
                    {products[productClicked].description ||
                      "No description available"}
                  </div>
                  <div
                    style={{
                      color: "#444",
                      fontSize: "1rem",
                      marginBottom: "15px",
                      fontFamily: "'Work Sans', sans-serif",
                    }}
                  >
                    <span style={{ color: "#666" }}>HSN Code: </span>
                    {products[productClicked].hsn || "Not available"}
                  </div>

                  {products[productClicked].customizations?.variants?.options[0]
                    ?.metal_info && (
                    <div
                      style={{
                        color: "#444",
                        fontSize: "1rem",
                        marginBottom: "15px",
                        fontFamily: "'Work Sans', sans-serif",
                      }}
                    >
                      <h3
                        style={{
                          marginBottom: "10px",
                          color: "#333",
                          fontSize: "1.1rem",
                        }}
                      >
                        Metal Information
                      </h3>

                      {(() => {
                        try {
                          const metalInfo =
                            products[productClicked].customizations.variants
                              .options[0].metal_info;
                          const keys = Object.keys(metalInfo);
                          return (
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  {keys.map((key) => (
                                    <th
                                      key={key}
                                      style={{
                                        textAlign: "left",
                                        padding: "8px",
                                        borderBottom: "1px solid #ddd",
                                        color: "#666",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.split("_").join(" ")}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {keys.map((key) => {
                                    const value = metalInfo[key];
                                    return (
                                      <td
                                        key={key}
                                        style={{
                                          padding: "8px",
                                          borderBottom: "1px solid #eee",
                                        }}
                                      >
                                        {key.includes("amount") ||
                                        key.includes("charge")
                                          ? "₹ "
                                          : ""}
                                        {typeof value === "number"
                                          ? value.toFixed(2)
                                          : value}
                                        {key.includes("perc") ||
                                        key.includes("percentage")
                                          ? "%"
                                          : ""}
                                        {key.includes("wt") ? " g" : ""}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          );
                        } catch (error) {
                          console.error("Error displaying metal_info:", error);
                          return <div>Unable to display metal information</div>;
                        }
                      })()}

                      <h3
                        style={{
                          marginBottom: "10px",
                          color: "#333",
                          fontSize: "1.1rem",
                        }}
                      >
                        Stone Information
                      </h3>

                      {(() => {
                        try {
                          const stoneInfo =
                            products[productClicked].customizations.variants
                              .options[0].stone_info;
                          const keys = Object.keys(stoneInfo);
                          if (!keys.length) return null;

                          return (
                            <table
                              style={{
                                width: "100%",
                                marginTop: "20px",
                                borderCollapse: "collapse",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              <thead>
                                <tr>
                                  {keys.map((key) => (
                                    <th
                                      key={key}
                                      style={{
                                        textAlign: "left",
                                        padding: "8px",
                                        borderBottom: "1px solid #ddd",
                                        color: "#666",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.split("_").join(" ")}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {keys.map((key) => {
                                    const value = stoneInfo[key];
                                    return (
                                      <td
                                        key={key}
                                        style={{
                                          padding: "8px",
                                          borderBottom: "1px solid #eee",
                                        }}
                                      >
                                        {key.includes("rate") ||
                                        key.includes("amount")
                                          ? "₹ "
                                          : ""}
                                        {typeof value === "number"
                                          ? value.toFixed(2)
                                          : value}
                                        {key.includes("perc") ||
                                        key.includes("percentage")
                                          ? "%"
                                          : ""}
                                        {key.includes("wt") ||
                                        key.includes("carat")
                                          ? " g"
                                          : ""}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          );
                        } catch (error) {
                          console.error("Error displaying stone_info:", error);
                          return <div>Unable to display stone information</div>;
                        }
                      })()}
                    </div>
                  )}
                </div>
              </div>
            )}
          </SwipeableDrawer>
        </ThemeProvider>
      )}
      <div className="head">
        <div className="head-txt">Products</div>
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">1 Product(s) </span>
          </p>
        </div>
      </div>
      <Divider />
      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          {products === null ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <>
              <TableContainer style={{ maxHeight: "95%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Admin Verified</TableCell>
                      <TableCell>View in Store</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row?.id}
                          >
                            <TableCell
                              className="name-content"
                              onClick={() => rowClicked(row)}
                            >
                              <div className="name-content-inner">
                                {row?.images ? (
                                  <img
                                    className="company-img"
                                    alt="product"
                                    src={`https://api.sadashrijewelkart.com/assets/${row?.images[0]?.file}`}
                                  />
                                ) : (
                                  <img
                                    className="company-img"
                                    alt="product"
                                    src={``}
                                  />
                                )}
                                {row?.name}
                              </div>
                            </TableCell>
                            <TableCell className="name-content">
                              <div className="name-content-inner">
                                <img
                                  className="company-img"
                                  alt="product"
                                  src={`https://api.sadashrijewelkart.com/assets/${row?.company?.logo}`}
                                />

                                {row.company.name}
                              </div>
                            </TableCell>
                            <TableCell>
                              ₹{row.customizations.variants.options[0]?.price}
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "5px 15px",
                                  backgroundColor:
                                    row.admin_verified === "1"
                                      ? "#cffbcf"
                                      : "#ffcfcf",
                                  borderRadius: "5px",
                                  color:
                                    row.admin_verified === "1"
                                      ? "#008000"
                                      : "#ff0000",
                                  height: "24px",
                                  width: "fit-content",
                                }}
                              >
                                {row.admin_verified === "1" ? "Yes" : "No"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <a
                                href={`https://sadashrijewelkart.com/item/${row.category}/${row.name}-${row.hash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#a36e29",
                                  textDecoration: "none",
                                }}
                              >
                                View
                              </a>
                            </TableCell>
                            <TableCell className="actions-content">
                              {row.admin_verified === "0" ? (
                                <div>
                                  <Done
                                    className="allow"
                                    onClick={() => allowProduct(row.id)}
                                  />
                                  <Close
                                    className="block"
                                    onClick={() => blockProduct(row.id)}
                                  />
                                </div>
                              ) : row.admin_verified === "1" ? (
                                <Close
                                  className="block"
                                  onClick={() => blockProduct(row.id)}
                                />
                              ) : row.admin_verified === "2" ? (
                                <div className="rejected">Blocked</div>
                              ) : (
                                <div>
                                  <Done
                                    className="allow"
                                    onClick={() => allowProduct(row.id)}
                                  />
                                  <Close
                                    className="block"
                                    onClick={() => blockProduct(row.id)}
                                  />
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </ThemeProvider>
      {/* Dialog to add a response for rejecting the product */}
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialogTitle-root": {
            fontSize: "1.5rem",
            fontWeight: 600,
            marginBottom: "10px",
          },
          "& .MuiDialogContent-root": {
            fontSize: "1.2rem",
            fontWeight: 400,
            marginBottom: "10px",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <DialogTitle>Add Rejection Message</DialogTitle>
          <Divider />
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            Please Enter the rejection message for rejecting the product.
            <br />
            <TextareaAutosize
              className="field"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              minRows={3}
              placeholder={`Enter message here`}
              style={{
                padding: "5px",
                marginBottom: "10px",
                marginTop: "10px",
                fontSize: "16px",
              }}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px", marginRight: "10px" }}>
            <Button onClick={() => setDialog(false)}>Close</Button>
            <Button
              variant="contained"
              onClick={handleBlockProductDialog}
              className="closeButton"
            >
              Send
            </Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
    </div>
  );
};

export default ProductComponent;
