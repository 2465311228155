import { Divider } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import CustomDrawer from "../../components/drawer/drawer.component";
import RatesComponent from "../../components/rates/rates.component";
import "./rates.styles.scss";

const RatesPage = () => {
  let { section } = useParams();

  return (
    <div className="rates">
      <CustomDrawer section={"rates"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <RatesComponent />
      </div>
    </div>
  );
};

export default RatesPage;
