import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PaymentSettlementModal = ({
  modalOpen,
  setModalOpen,
  unSettledPaymentList,
}) => {
  const [selectedSeller, setSelectedSeller] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleSellerChange = (event) => {
    setSelectedSeller(event.target.value);
    setSelectedOrders([]);
  };

  const handleOrderChange = (event) => {
    setSelectedOrders(event.target.value);
  };

  const handleDelete = (item) => {
    setSelectedOrders(selectedOrders.filter((order) => order !== item));
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handlePay = () => {
    const payment_public_ids = selectedOrders.map(
      (order) => order.payment_public_id
    );

    const admin = JSON.parse(localStorage.getItem("admin"));

    const sellerId = selectedOrders[0]?.seller_id;
    console.log("Seller ID:", sellerId);

    axios
      .post(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/settlements/settlements.php?type=create_settlement",
        {
          type: "settle_payment",
          settlement_order_payment_id: payment_public_ids,
          seller_id: sellerId,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("Error creating settlement:", error);
      });
  };

  useEffect(() => {
    const prices = selectedOrders.map((order) =>
      Number(order.settlement_amount)
    );
    setTotalAmount(
      numberWithCommas(prices.reduce((prev, curr) => prev + curr, 0))
    );
  }, [selectedOrders]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: "20px",
          position: "absolute",
          padding: "25px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "0px solid white",
          width: "50%",
          height: 450,
          bgcolor: "background.paper",
          border: "2px solid #fff",
        }}
      >
        <p
          style={{
            fontSize: "1.7rem",
            margin: 0,
            fontWeight: "bold",
            width: "95%",
            margin: "auto",
            fontFamily: "'Work Sans', sans-serif",
          }}
        >
          Settle Payment
        </p>

        <div
          style={{
            width: "95%",
            margin: "auto",
            display: "flex",
            justifyItems: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "15px",
              marginTop: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="seller-select-label">Select Seller</InputLabel>
              <Select
                labelId="seller-select-label"
                id="seller-select"
                value={selectedSeller}
                onChange={handleSellerChange}
                input={<OutlinedInput label="Select Seller" />}
                MenuProps={MenuProps}
              >
                {Object.keys(unSettledPaymentList).map((seller) => (
                  <MenuItem key={seller} value={seller}>
                    {seller}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              marginBottom: "15px",
              width: "100%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="order-select-label">
                Select Orders to Settle
              </InputLabel>
              <Select
                labelId="order-select-label"
                id="order-select"
                multiple
                fullWidth
                value={selectedOrders}
                onChange={handleOrderChange}
                input={<OutlinedInput label="Select Orders to Settle" />}
                MenuProps={MenuProps}
              >
                {selectedSeller &&
                  unSettledPaymentList[selectedSeller].map((order) => (
                    <MenuItem key={order.order_id} value={order}>
                      {order.order_id}: Rs{" "}
                      {numberWithCommas(Number(order.settlement_amount))}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            display: "flex",
            margin: "auto",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <Stack direction="row" spacing={1}>
            {selectedOrders.map((order) => (
              <Chip
                key={order.order_id}
                label={`${order.order_id}: Rs ${numberWithCommas(
                  Number(order.settlement_amount)
                )}`}
                variant="outlined"
                style={{
                  padding: "10px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  borderRadius: "50px",
                }}
                onDelete={() => handleDelete(order)}
              />
            ))}
          </Stack>
        </div>
        <div
          style={{
            width: "95%",
            display: "flex",
            margin: "auto",
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              marginLeft: "auto",
              width: "340px",
              height: "60px",
              borderRadius: "10px",
              border: "2px solid lightgray",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              fontSize: "1.3rem",
            }}
          >
            <p>Total Amount:</p>
            <p style={{ color: "rgba(0,0,0,0.6)" }}>
              <b>Rs {totalAmount}</b>
            </p>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <Button
            style={{
              marginLeft: "auto",
              width: "150px",
              height: "50px",
              color: "black",
              border: "0px",
              fontWeight: 600,
            }}
            variant="outlined"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              marginLeft: "20px",
              width: "250px",
              height: "50px",
              color: "white",
              backgroundColor: "#A36E29",
              fontWeight: 600,
            }}
            onClick={handlePay}
          >
            Pay Now
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PaymentSettlementModal;
